import {AbiItem} from 'web3-utils';
import {ETH_NETWORK} from '@/utils/contracts/network';

/**
 * Contract addresses
 */

const getWRContractAddress = (network: string): string => {
  const address = process.env.NEXT_PUBLIC_SHIBUYA_SMART_CONTRACT_ADDRESS;

  if (address && address.length > 0) {
    return address;
  }

  // NB: the code below should not be used until we can streamline deployments + seeding data
  // (At the moment there are a few manual steps required to set up the platform)
  switch (network) {
    case 'goerli':
      const data = require('../../generated/addresses/WhiteRabbit.goerli.json');

      return data.address;
    case 'ropsten':
    case 'hardhat':
    case 'localhost':
      throw new Error(`Address not available for network: ${network}`);
    default:
      throw new Error(`Address not available for network: ${network}`);
  }
};

const getShibuyaCannesNFTContractAddress = (network: string): string => {
  const address = process.env.NEXT_PUBLIC_SHIBUYA_CANNES_NFT_CONTRACT_ADDRESS;

  if (address && address.length > 0) {
    return address;
  }

  // NB: the code below should not be used until we can streamline deployments + seeding data
  // (At the moment there are a few manual steps required to set up the platform)
  switch (network) {
    case 'goerli':
      const data = require('../../generated/addresses/ShibuyaCannes.goerli.json');

      return data.address;
    case 'ropsten':
    case 'hardhat':
    case 'localhost':
      throw new Error(`Address not available for network: ${network}`);
    default:
      throw new Error(`Address not available for network: ${network}`);
  }
};

const getDominionXFrameNFTContractAddress = (network: string): string => {
  const address = process.env.NEXT_PUBLIC_DOMINIONX_FRAME_NFT_CONTRACT_ADDRESS;

  if (!address) {
    throw new Error(
      'NEXT_PUBLIC_DOMINIONX_FRAME_NFT_CONTRACT_ADDRESS env variable is required!'
    );
  }

  return address;
};

/**
 * Contract ABIs
 */

const getWRContractAbi = () => {
  const metadata = require('../../generated/contracts/WhiteRabbit.json');

  return metadata.abi as Array<AbiItem>;
};

const getShibuyaCannesNFTContractAbi = () => {
  const metadata = require('../../generated/contracts/ShibuyaCannes.json');

  return metadata.abi as Array<AbiItem>;
};

const getDominionXFrameNFTContractAbi = () => {
  const metadata = require('../../generated/contracts/DominionXFrameNFT.json');
  return metadata.abi as Array<AbiItem>;
};

export const WR_CONTRACT_ADDRESS = getWRContractAddress(ETH_NETWORK);

// Cannes fundraiser
export const SHIBUYA_CANNES_NFT_CONTRACT_ADDRESS =
  getShibuyaCannesNFTContractAddress(ETH_NETWORK);

export const DOMINIONX_FRAME_NFT_CONTRACT_ADDRESS =
  getDominionXFrameNFTContractAddress(ETH_NETWORK);
export const WR_CONTRACT_ABI = getWRContractAbi();

// Cannes fundraiser
export const SHIBUYA_CANNES_NFT_CONTRACT_ABI = getShibuyaCannesNFTContractAbi();

export const DOMINIONX_FRAME_NFT_CONTRACT_ABI =
  getDominionXFrameNFTContractAbi();
export const HOUSE_OF_LEE_CONTRACT_ADDRESS =
  '0x091fc2e2bb9886d107ba3537defa42744282eaaa';
